import React from 'react'
import './App.scss'
import Scheduler from './pages/Scheduler'
import PlantSchedule from './pages/PlantSchedule'
import Login from './pages/Login'
import PlantHours from './pages/PlantHours'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

const App = () => {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/">
						<Route index element={<Scheduler />} />
						<Route path="/plant-schedule" element={<PlantSchedule />} />
						<Route path="/login" element={<Login />} />
						<Route path="/plant-hours" element={<PlantHours />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	)
}

export default App
