import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { isAfter, isWeekend, nextMonday } from 'date-fns'
import { DependencyStore, EventStore } from '@bryntum/scheduler'
import dayjs from 'dayjs'
import { fetcher } from 'src/fetcher'
import './JobScheduleTable.scss'
import { EventEntry, GroupedJobsData } from '@app/shared'
import { CloseOutlined } from '@ant-design/icons'

type JobScheduleTableProps = {
	group: GroupedJobsData[]
	jobsScheduled: GroupedJobsData[]
	eventStore: EventStore
	dependencyStore: DependencyStore
	date: dayjs.Dayjs
	btnAction: string
	setBtnAction: (data: string) => void
	setScheduleStatus: (data: string) => void
	setReady: (data: boolean) => void
	getJobsUnScheduledData?: () => Promise<void>
}

const JobScheduleTable = (props: JobScheduleTableProps) => {
	const {
		group,
		jobsScheduled,
		eventStore,
		// dependencyStore,
		date,
		btnAction,
		setBtnAction,
		setScheduleStatus,
		setReady,
		getJobsUnScheduledData,
	} = props

	const [jobs, setJobs] = useState([])
	const [readyToScheduleJobs, setReadyToScheduleJobs] = useState([])
	const [scheduledJobs, setScheduledJobs] = useState<GroupedJobsData[]>([])
	useEffect(() => {
		setScheduledJobs(
			jobsScheduled.map((item) => ({
				...item,
				key: item.value,
			}))
		)
	}, [jobsScheduled])

	const roundToNearestQuarterHour = (duration: number) => {
		return Math.ceil(duration / 15) * 15
	}

	const loop = (
		start: Date,
		stages: EventEntry[],
		existsStages: any,
		prevResourceId: number,
		newStages: EventEntry[]
	) => {
		let shouldBreak = false
		let _stages = [...stages]

		for (let i = 0; i < _stages.length; i++) {
			shouldBreak = false
			const stage = _stages[i]

			if (!stage.startDate && !stage.endDate) {
				const duration =
					typeof stage.Duration === 'string'
						? parseInt(stage.Duration, 10)
						: stage.Duration
				const roundedDuration = roundToNearestQuarterHour(duration)

				if (Boolean(existsStages) && stage.resourceId !== prevResourceId) {
					const lastJobOfResourceIndex = existsStages?.findLastIndex(
						(item) => item.resourceId === stage.resourceId
					)
					if (lastJobOfResourceIndex !== -1) {
						if (
							!isAfter(
								start,
								new Date(existsStages[lastJobOfResourceIndex].endDate)
							)
						) {
							start = new Date(existsStages[lastJobOfResourceIndex].endDate)
						}
					}
				}

				let endTimeOfCurrentDate = new Date(
					dayjs(start).toDate().setHours(17, 0, 0, 0)
				)

				if (dayjs(start).isSame(endTimeOfCurrentDate, 'minute')) {
					start = new Date(
						dayjs(start).add(1, 'day').toDate().setHours(7, 0, 0, 0)
					)
				}

				if (isWeekend(start)) {
					start = new Date(nextMonday(start).setHours(7, 0, 0, 0))
				}

				let end = new Date(
					start.getTime() +
						(stage.SegDuration ? stage.SegDuration : roundedDuration) * 60000
				)

				stage.startDate = start.toISOString()
				stage.endDate = end.toISOString()
				stage.Duration = roundedDuration

				start = new Date(stage.endDate)
				prevResourceId = stage.resourceId

				if (
					dayjs(end).isSame(endTimeOfCurrentDate, 'date') &&
					dayjs(end).isAfter(endTimeOfCurrentDate)
				) {
					stage.endDate = endTimeOfCurrentDate.toISOString()
					start = new Date(
						dayjs(start).add(1, 'day').toDate().setHours(7, 0, 0, 0)
					)

					_stages.splice(i, 0, {
						...stage,
						startDate: null,
						endDate: null,
						SegDuration:
							roundedDuration -
							dayjs(stage.endDate).diff(stage.startDate, 'minute'),
					})
					shouldBreak = true
				}
				stage.SegDuration = dayjs(stage.endDate).diff(stage.startDate, 'minute')
				newStages.push(stage)
				if (shouldBreak) {
					break
				}
			}
		}
		if (shouldBreak) {
			loop(start, _stages, existsStages, prevResourceId, newStages)
		}
	}

	const addStartEndDates = (props: { stages: any[]; existsStages?: any }) => {
		const { stages, existsStages } = props
		let start = date.toDate()
		start.setHours(7, 0, 0, 0)
		let prevResourceId = 0
		let newStages = []

		loop(start, stages, existsStages, prevResourceId, newStages)

		return newStages
	}

	const onChange = async (keys: number[]) => {
		setReady(false)
		const eventsData = Array.from(
			eventStore
				.getEvents({
					startDate: new Date(
						date.add(-1, 'day').toDate().setHours(7, 0, 0, 0)
					),
					endDate: new Date(date.add(1, 'day').toDate().setHours(17, 0, 0, 0)),
				})
				.values()
		).map((item) => item.data)

		let events = eventsData
		let dependencies = []

		let newEvents = []
		let newGroupEvents = []

		jobsScheduled?.forEach((item) => {
			const items = item.items
			items?.forEach((item, idx) => {
				if (idx - 1 !== -1) {
					dependencies.push({
						id: item?.id,
						to: item?.id,
						from: items[idx - 1]?.id,
					})
				}
			})
		})

		for (let i = 0; i < keys.length; i++) {
			const gr = group.find((gr) => gr.value === keys[i])

			if (gr) {
				const children = gr.children
				children.forEach((item, idx) => {
					if (idx - 1 !== -1) {
						dependencies.push({
							id: item?.id,
							to: item?.id,
							from: children[idx - 1]?.id,
						})
					}
				})

				let evs = []
				if (events.length) {
					evs = addStartEndDates({
						stages: children,
						existsStages: events,
					})
				} else {
					evs = addStartEndDates({ stages: children })
				}
				events = [...events, ...evs]
				newEvents = [...newEvents, ...evs]
				newGroupEvents = [...(newGroupEvents ?? []), gr]
			}
		}

		// events.forEach((event) => {
		// 	eventStore.add(event)
		// })
		// await eventStore.project.commitAsync()
		// dependencies.forEach((dep) => {
		// 	dependencyStore.add(dep)
		// })
		// await dependencyStore.project.commitAsync()
		setReady(false)

		return {
			newEvents,
			newGroupEvents,
		}
	}

	const newOnChange = (value) => {
		if (value) {
			setJobs((prev) => {
				const _prev = [...prev]
				const index = prev.indexOf(value)
				if (index !== -1) {
					_prev.splice(index, 1)
					return _prev
				} else {
					return [..._prev, value]
				}
			})
		}
	}

	const columns = [
		{
			title: 'Work Order',
			dataIndex: 'label',
			key: 1,
			width: '35%',
		},
		{
			title: 'SeqNum',
			dataIndex: 'SeqNum',
			key: 2,
			width: '7%',
		},
		{
			title: 'StageName',
			dataIndex: 'StageName',
			key: 3,
		},
		{
			title: 'Resource',
			dataIndex: 'Resource',
			key: 4,
		},
		{
			title: 'Duration',
			dataIndex: 'Duration',
			key: 5,
			width: '10%',
		},
	]

	const scheduledColumns = [
		{
			title: 'Work Order',
			dataIndex: 'label',
			key: 1,
		},
	]

	useEffect(() => {
		const jobsData = group?.map((gr, idx) => {
			return {
				key: gr.value,
				label: gr.label,
				checkable: true,
				children: gr.children.map((child, idx) => ({
					key: `${gr.value} - ${child.Resource} - ${child.ProdCode}`,
					DocNum: child.DocNum,
					SeqNum: child.SeqNum,
					StageName: child.StageName,
					Resource: child.Resource,
					Duration: child.Duration,
					checkable: false,
					ProdCode: child.ProdCode,
				})),
			}
		})
		setReadyToScheduleJobs(jobsData)
	}, [group])

	const rowSelection = {
		onSelect: (record) => {
			newOnChange(record.key)
		},
		renderCell: (_checked, record, _index, originNode) => {
			return <>{record.checkable ? originNode : null}</>
		},
	}

	const scheduledTableRowSelection = {
		renderCell: (_checked, record, _index, _originNode) => {
			return (
				<Button
					onClick={() => {
						handleDeleteJobs(record.key)
					}}
					danger
					className="flex justify-center items-center"
					size="small"
					shape="circle"
					icon={<CloseOutlined />}
				/>
			)
		},
	}

	const handleDeleteJobs = (DocNum: number) => {
		fetcher
			.delete('/schedule/doc-num/' + encodeURIComponent(DocNum))
			.then((res) => {
				setScheduleStatus('delete success')
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleSaveJobScheduled = async (jobs: any) => {
		fetcher
			.post(`/schedule`, {
				jobs,
			})
			.then(async (res) => {
				setScheduleStatus('add success')
				await getJobsUnScheduledData?.()
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleScheduleJobs = async () => {
		if (jobs.length < 1) return
		const data = await onChange(jobs)
		await handleSaveJobScheduled(
			data.newEvents.map((event) => ({
				...event,
				endDate: null,
				startDate: null,
			}))
		)

		setReadyToScheduleJobs((prev) =>
			prev.filter((item) => !jobs.includes(item.key))
		)

		setJobs([])
	}

	useEffect(() => {
		if (btnAction === 'btn_schedule_jobs') {
			handleScheduleJobs()
			setBtnAction('')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [btnAction])

	return (
		<>
			<div className="top-container">
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<h3>Add to Schedule</h3>
				</div>

				<Table
					className=""
					columns={columns}
					dataSource={readyToScheduleJobs}
					scroll={{
						y: 275,
					}}
					size="small"
					pagination={{
						position: ['bottomCenter'],
					}}
					rowSelection={{
						...rowSelection,
						hideSelectAll: true,
						checkStrictly: false,
					}}
				/>
			</div>

			<div className="bottom-container">
				<h3>Scheduled jobs</h3>

				<Table
					columns={scheduledColumns}
					dataSource={[...scheduledJobs]}
					scroll={{
						y: 275,
					}}
					size="small"
					pagination={{
						position: ['bottomCenter'],
					}}
					rowSelection={{
						...scheduledTableRowSelection,
						hideSelectAll: true,
					}}
				/>
			</div>
		</>
	)
}

export default JobScheduleTable
